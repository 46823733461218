<template>
  <div class="tips">
    <p class="tit">使用说明</p>
    <div class="msg">
      <p>· 商城搜索支持任意关键词、链接、口令搜索。</p>
      <p>· 关键词搜索将会搜索所有相关产品。</p>
      <p>· 链接/口令搜索为精准匹配，并转换为您的专属链接/口令</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.tips {
  padding: 0 15px;
  margin-top: 50px;
  .tit {
    font-size: 12px;
    font-weight: 600;
    color: #333333;
    line-height: 17px;
  }
  .msg {
    margin-top: 10px;
    p {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
}
</style>