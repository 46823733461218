<template>
  <div class="error">
    <img src="../../../assets/empty.png" alt="" />
    <div class="tit">
      <p>链接/口令识别失败</p>
    </div>
    <div class="msg">
      <p>可能的失败原因</p>
      <p>暂不支持该商城</p>
      <p>暂不支持此种链接/口令</p>
      <p>该商品/活动不支持推广</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.error {
  width: 100%;
  padding: 10px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 215px;
    height: 174px;
  }
  .tit {
    display: flex;
    align-items: center;
    p {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
  .msg {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
}
</style>