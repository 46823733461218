<template>
  <div class="searchList">
    <div class="itemList" v-for="item in details.resultList" :key="item.typeId">
      <h3>
        <span>{{ item.name }}</span
        >中的结果
      </h3>
      <div
        v-show="item.list.length"
        class="item"
        @click="jumpUrl(itemProduct)"
        v-for="itemProduct in item.list"
        :key="itemProduct.contentId"
      >
        <div class="img">
          <img :src="itemProduct.coverImage" alt="" />
          <div v-if="itemProduct.estimateCustomerCommission">
            <p>预计返{{itemProduct.estimateCustomerCommission/100}}元</p>
          </div>
        </div>
        <div class="msg">
          <p class="name">{{ itemProduct.title }}</p>
          <p class="price">{{ itemProduct.subTitle }}</p>
          <div class="bot">
            <p class="time">
              <span>{{ itemProduct.updateTime }}</span>
              <i></i>
              <span>{{ itemProduct.merchantName }}</span>
            </p>
            <p class="num">
              <van-icon size="16px" color="#999" name="eye-o" /><span>{{
                itemProduct.displayHits
              }}</span>
            </p>
          </div>
        </div>
      </div>
      <p v-show="item.list.length" class="more" @click="getMore(item)">
        查看更多
        <van-icon name="arrow" />
      </p>
      <van-empty
        v-show="!item.list.length"
        image="search"
        description="暂无结果"
      />
    </div>
    <!-- <div class="itemList" v-for="item in details.resultList" :key="item.typeId">
      <h3>
        <span>{{ item.name }}</span
        >中的结果
      </h3>
      <div
        v-show="item.list.length"
        class="item"
        @click="jumpUrl(itemProduct)"
        v-for="itemProduct in item.list"
        :key="itemProduct.contentId"
      >
        <div class="img">
          <img :src="itemProduct.coverImage" alt="" />
        </div>
        <div class="msg">
          <p class="name">{{ itemProduct.title }}</p>
          <p class="price">{{ itemProduct.subTitle }}</p>
          <p class="num">
            <van-icon size="20px" name="eye-o" /><span>{{
              itemProduct.displayHits
            }}</span>
          </p>
          <p class="time">
            <span>{{ itemProduct.updateTime }}</span>
            <i></i>
            <span>{{ itemProduct.merchantName }}</span>
          </p>
        </div>
      </div>
      <p v-show="item.list.length" class="more" @click="getMore(item)">
        查看更多
      </p>
      <van-empty
        v-show="!item.list.length"
        image="search"
        description="暂无结果"
      />
    </div> -->
  </div>
</template>

<script>
export default {
  props: ["details", "keyword", "shopId"],
  data() {
    return {};
  },
  created() {},
  methods: {
    // 跳转商品
    jumpUrl(item) {
      window.location.href = item.url;
    },
    // 查看更多
    getMore(item) {
      this.$router.push({
        path: `/${this.shopId}/searchMore`,
        query: {
          name: item.name,
          keyword: this.keyword,
          typeId: item.typeId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #ff804d;
.searchList {
  width: 100%;
  padding: 15px 15px;
  .itemList {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 15px;
    margin-bottom: 20px;
    h3 {
      width: fit-content;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      height: 20px;
      font-size: 14px;
      font-weight: 600;
      color: #333333;
      line-height: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 0 12px;
      border-bottom: 6px solid $primary-color;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .item {
      width: 100%;
      border-bottom: 1px solid #eeeeee;
      padding: 20px 0;
      display: flex;
      align-items: center;
      .img {
        width: 80px;
        height: 80px;
        position: relative;
        img {
          width: 80px;
          height: 80px;
        }
        div {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 100;
          width: 100%;
          height: 18px;
          background: $primary-color;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        p {
          white-space:nowrap; 
          color: #fff;
          font-size: 20px;
          line-height: 10px;
          transform: scale(0.5);
        }
      }
      .msg {
        width: 100%;
        // height: 100px;
        padding: 5px 0;
        flex: 1;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name {
          font-size: 12px;
          line-height: 17px;
          font-weight: bold;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; /*行数*/
          -webkit-box-orient: vertical;
          text-align: justify;
        }
        .price {
          font-size: 12px;
          line-height: 12px;
          color: #e62828;
          margin: 10px 0 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1; /*行数*/
          -webkit-box-orient: vertical;
          text-align: justify;
        }
        .bot {
          display: flex;
          align-items: center;
          justify-content: space-between;

          margin-top: 12px;
        }
        .num {
          display: flex;
          align-items: center;
          // margin: 5px 0;
          span {
            font-size: 12px;
            color: #999;
            line-height: 12px;
            margin-left: 3px;
          }
        }
        .time {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #999;
          line-height: 12px;
          i {
            display: block;
            width: 1px;
            height: 12px;
            background: #999;
            margin: 0 5px;
          }
        }
      }
    }
    .item:last-child {
      border-bottom: none !important;
    }
    .more {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      font-size: 12px;
      line-height: 12px;
      font-weight: bold;
      color: #333;
      cursor: pointer;
      /deep/ .van-icon {
        color: #333;
        font-weight: bold;
      }
    }
  }
  // .itemList {
  //   width: 100%;
  //   margin-bottom: 50px;
  //   h3 {
  //     font-size: 14px;
  //     line-height: 14px;
  //     color: #333;
  //     margin-bottom: 10px;
  //     span {
  //       font-weight: bold;
  //     }
  //   }
  //   .item {
  //     width: 100%;
  //     border: 1px solid #eaeaea;
  //     padding: 10px;
  //     display: flex;
  //     align-items: center;
  //     .img {
  //       width: 100px;
  //       img {
  //         width: 100px;
  //         height: 100px;
  //       }
  //     }
  //     .msg {
  //       width: 100%;
  //       // height: 100px;
  //       padding: 5px 0;
  //       flex: 1;
  //       margin-left: 10px;
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: space-between;
  //       .name {
  //         font-size: 16px;
  //         line-height: 20px;
  //         color: #333;
  //         overflow: hidden;
  //         text-overflow: ellipsis;
  //         display: -webkit-box;
  //         -webkit-line-clamp: 2; /*行数*/
  //         -webkit-box-orient: vertical;
  //         text-align: justify;
  //       }
  //       .price {
  //         font-size: 16px;
  //         line-height: 20px;
  //         color: rgb(196, 62, 62);
  //         margin: 5px 0 0;
  //         overflow: hidden;
  //         text-overflow: ellipsis;
  //         display: -webkit-box;
  //         -webkit-line-clamp: 1; /*行数*/
  //         -webkit-box-orient: vertical;
  //         text-align: justify;
  //       }
  //       .num {
  //         display: flex;
  //         align-items: center;
  //         margin: 5px 0;
  //         span {
  //           padding-left: 3px;
  //           font-size: 14px;
  //           color: #666;
  //           line-height: 16px;
  //         }
  //       }
  //       .time {
  //         display: flex;
  //         align-items: center;
  //         font-size: 14px;
  //         color: #666;
  //         line-height: 14px;
  //         i {
  //           display: block;
  //           width: 1px;
  //           height: 14px;
  //           background: #666;
  //           margin: 0 5px;
  //         }
  //       }
  //     }
  //   }
  //   .more {
  //     width: 100%;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     margin-top: 20px;
  //     font-size: 14px;
  //     line-height: 14px;
  //     color: $primary-color;
  //     text-decoration: underline;
  //     cursor: pointer;
  //   }
  // }
}
</style>