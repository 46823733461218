<template>
  <div class="error">
    <img src="../../../assets/empty.png" alt="" />
    <div class="tit">
      <p>啊哦，没有您想要的呢，去看看别的吧</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.error {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  img {
    width: 215px;
    height: 174px;
  }
  .tit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    p {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
}
</style>