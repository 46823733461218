<template>
  <div class="search">
    <div class="input-txt">
      <van-field
        v-model="keyword"
        rows="3"
        autosize
        type="textarea"
        placeholder="搜索商品名/品牌/链接/口令"
        show-word-limit
        clearable
        ref="input"
      />
      <div class="hotSearch" v-if="hotList && hotList.length != 0">
        <p class="tit">热搜11：</p>
        <div class="list">
          <span>足浴</span>
        </div>
      </div>
      <div class="btn">
        <van-button
          type="primary"
          style="width: 73%; margin: 0 auto; margin-top: 30px"
          round
          @click="getSearchList"
          >立即搜索</van-button
        >
      </div>
    </div>
    <searchTips v-show="active === 1"></searchTips>

    <searchList
      :shopId="shopId"
      v-show="active === 2"
      :details="details"
      :keyword="keyword"
    ></searchList>
    <searchItem v-show="active === 3" :details="details"></searchItem>
    <searchError v-show="active === 4"></searchError>
    <searchNone v-show="active === 5"></searchNone>
    <customerService />
  </div>
</template>

<script>
import searchTips from "././component/searchTips";
import searchList from "././component/searchList";
import searchItem from "././component/searchItem";
import searchError from "././component/searchError";
import searchNone from "././component/searchNone";
import { getSearchList,getShopName,topSearch } from "../../utils/api";
import customerService from "../../component/customerService";
export default {
  components: {
    searchTips,
    searchList,
    searchItem,
    searchError,
    searchNone,
    customerService,
  },
  data() {
    return {
      keyword: "",
      details: {},
      active: 1,
      isFirstEnter: false,
      shopId: "",
      shopMsg:{},
      hotList:null,//热搜词列表
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "searchMore") {
      to.meta.isBack = true;
    }
    next();
  },
  async activated() {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      this.keyword = "";
      this.details = {};
      this.active = 1;
      this.isFirstEnter = false;
      this.$refs["input"].focus();
      document.title = "商城搜索";
      await this.getshopName();
      await this.topSearch();
    }
    this.$route.meta.isBack = false;
    this.isFirstEnter = false;
  },
  created() {
    
    // this.shopId = window.location.pathname.split("/")[1];
  },
  mounted() {
    this.isFirstEnter = true;
  },
  methods: {
    //热搜列表
    async topSearch(){
      let params = {
        shopId:this.shopId
      }
      await topSearch(params).then(res => {
        console.log(res)
        this.hotList = res.data;
      })
    },
    //获取店铺信息
    async getshopName() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await getShopName().then((res) => {
        this.shopId = res.id;
        this.shopMsg = res;
        this.$toast.clear();
      });
    },
    //搜索结果
    getSearchList() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let fd = new FormData();
      fd.append("keyword", this.keyword);
      fd.append("shopId", this.shopId);
      getSearchList(fd).then((res) => {
        this.$toast.clear();
        if (res.success) {
          this.details = res.data;
          if (res.data.success) {
            if (res.data.type === 1) {
              this.active = 3;
            } else if (res.data.type === 2) {
              this.active = 2;
            }
          } else {
            if (res.data.resultList) {
              this.active = 5;
            } else {
              this.active = 4;
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #ff804d;
.btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.hotSearch {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  .tit {
    white-space: nowrap;
    font-size: 12px;
    // transform: scale(0.5);
    font-weight: 400;
    color: #999999;
    line-height: 14px;
  }
  .list {
    display: flex;
    align-items: center;
    span {
      font-size: 10px;
      transform: scale(1);
      font-weight: 400;
      color: #ffffff;
      line-height: 14px;
      padding: 0 5px;
      background: #ffbba0;
      border-radius: 8px;
      margin-right: 5px;
    }
  }
}
/deep/ .van-cell {
  background: #fcfcfc;
  border-radius: 10px;
  height: 150px;
  border: 1px solid #eeeeee;
}
.input-txt {
  padding: 15px;
}
/deep/ .van-button--primary {
  background: $primary-color;
  border: 1px solid $primary-color;
}
/deep/ .van-button {
  height: 41px;
}
</style>